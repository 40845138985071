import React, { useState } from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import tw from 'tailwind.macro'
import ProgressiveImage from 'react-progressive-image'

import { Layout, Grid, Slider, Block } from '../components'
import { media } from '../styles/utils'
import { container, padding, bgImage, bgIcon, maxWidth, button } from '../styles/global'

import greyPattern from '../assets/images/pattern-grey.svg'

const ProductSingle = props => {
    const { previewData, pageContext } = props
    const data = previewData || {
        title: pageContext?.title,
        slug: pageContext?.slug,
        ...pageContext.acf,
    }
    console.log(data)

    const renderIntro = () => {
        return (
            <Intro
                highlightColour={data?.product_highlight_colour}
            >
                <Container>
                    <Info>
                        <Heading>{data.title}</Heading>

                        {data.product_description && (
                            <Description
                                dangerouslySetInnerHTML={{
                                    __html: data.product_description,
                                }}
                            />
                        )}
                    </Info>
                </Container>
                {data.product_feature_image && (
                    <Image
                        key={data.product_feature_image.sizes.medium2}
                        src={data.product_feature_image.sizes.medium2}
                    >
                        {(src, loading) => {
                            return (
                                <BGImage
                                    image={src}
                                    style={{ opacity: loading ? 0 : 1 }}
                                />
                            )
                        }}
                    </Image>
                )}
            </Intro>
        )
    }

    const renderColours = () => {
        if (!data.product_colours) return
        const items = data.product_colours.map((item, i) => {
            return (
                <Item key={i}>
                    {item.image && (
                        <Image
                            key={item.image.sizes.medium2}
                            src={item.image.sizes.medium2}
                        >
                            {(src, loading) => {
                                return (
                                    <LoadedImage 
                                        src={src} 
                                        style={{opacity: loading ? 0 : 1}}  
                                    />
                                )
                            }}
                        </Image> 
                    )}
                    <Info>
                        {item.title && (
                            <Heading>{item.title}</Heading>
                        )}
                        {item.product_number && (
                            <Description>Product No: {item.product_number}</Description>
                        )}
                    </Info>
                </Item>
            )
        })

        return (
            <Colours>
                <Heading>Variations of {data.title}</Heading>
                <Grid
                    columns={4}
                    items={items}
                />
            </Colours>
        )
    }

    const renderSpecification = () => {
        if (!data.product_specifications) return

        const items = data.product_specifications.map((item, i) => {
            return (
                <Item key={i}>
                    {item.label}{`: `}{item.value}
                </Item>
            )
        })

        return (
            <Specifications>
                {items}
            </Specifications>
        )
    }

    const renderFeatures = () => {
        if (!data.product_features) return

        const items = data.product_features.map((item, i) => {
            return (
                <Item key={i}>
                    {item.image && (
                        <Icon
                            src={item?.image?.sizes?.medium2} 
                        />
                    )}
                    {item.text && (
                        <Description
                            dangerouslySetInnerHTML={{
                                __html: item.text,
                            }}
                        />
                    )}
                </Item>
            )
        })

        return (
            <Features
                highlightColour={data?.product_highlight_colour}
            >
                <Container>
                    <Heading>{data.title} features include</Heading>
                    <Items>
                        {items}
                    </Items>
                </Container>
            </Features>
        )
    }

    const renderGallery = () => {
        if (!data.product_gallery) return

        return (
            <Gallery>
                <Heading>Beautiful homes using {data.title}</Heading>
                <Slider
                    slides={data.product_gallery}
                />
            </Gallery>
        )
    }

    const renderBreakdown = () => {
        if (!data.breakdown_accreditations) return
        const items = data?.breakdown_accreditations?.map((item, i) => {
            return (
                <Item key={i}>
                    {item.image && (
                        <Icon
                            src={item?.image?.sizes?.medium2} 
                        />
                    )}
                </Item>
            )
        })

        return (
            <Breakdown itemCount={data?.breakdown_accreditations?.length}>
                <Heading>{data?.breakdown_title}</Heading>
                <Image
                    key={data?.breakdown_image?.sizes?.medium2}
                    src={data?.breakdown_image?.sizes?.medium2}
                >
                    {(src, loading) => {
                        return (
                            <LoadedImage
                                src={src}
                                style={{opacity: loading ? 0 : 1}}  
                            />
                        )
                    }}
                </Image>
                <Items>
                    {items && items}
                </Items>
            </Breakdown>
        )
    }

    const renderDownloads = () => {
        if (!data.product_downloads) return

        const items = data.product_downloads.map((item, i) => {
            return (
                <Item key={i}>
                    <Heading>
                        {data?.title}<br/>
                        {item?.title}
                    </Heading>
                    <Image
                        key={item?.image?.sizes?.medium2}
                        src={item?.image?.sizes?.medium2}
                    >
                        {(src, loading) => {
                            return (
                                <LoadedImage
                                    src={src} 
                                    style={{opacity: loading ? 0 : 1}}  
                                />
                            )
                        }}
                    </Image>
                    <Button
                        as={ExternalLink}
                        href={item?.file}
                        target="_blank"
                    >
                        Download
                    </Button>
                </Item>
            )
        })

        return (
            <Downloads>
                <Items>
                    {items}
                </Items>
            </Downloads>
        )
    }

    return (
        <Layout meta={data && data.seo}>
            <Wrapper>
                {renderIntro()}

                <Container>
                    {renderColours()}
                    {renderSpecification()}
                </Container>

                {renderFeatures()}

                <Container>
                    {renderGallery()}
                    {renderBreakdown()}
                </Container>

                {renderDownloads()}
                
                <Block
                    layout={'stockists_block'}
                />
            </Wrapper>
        </Layout>
    )
}

// Shared

const Heading = styled.div`
    ${tw`font-medium`};
    font-size: 2rem;
`
const Subheading = styled.div``
const Description = styled.div``
const Info = styled.div``
const Items = styled.div``
const Item = styled.div``
const ExternalLink = styled.a``
const Button = styled.div`
    ${button}
`

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`

const BGImage = styled.div`
    background-image: url(${props => props.image});
    ${bgImage};
    transition: opacity 0.45s ease;
`

const LoadedImage = styled.img`
    transition: opacity 0.45s ease;
`

const Icon = styled.img``

// Layout

const Wrapper = styled.div`
    ${tw`w-full`};
`

const Container = styled.div`
    ${container};
    ${padding};
`

// Intro

const Intro = styled.div`
    ${tw`relative flex`};
    overflow: hidden;

    ${BGImage} {
        ${tw`absolute inset-0 w-full h-full`};
        object-fit: cover;
    }

    ${Container} {
        ${tw`relative`};
    }

    ${Info} {
        ${tw`relative z-10 w-1/2`};
        margin: 5rem 0;
        padding: 5rem;
        background-color: rgba(158, 201, 83, 0.7);

        ${props => {
            if (props.highlightColour === 'gold')
                return css`
                    background-color: rgba(140, 115, 75, 0.7);
                `
        }}

        ${media.mob`
            ${tw`w-full`};
            padding: 3rem;
        `}
    }

    ${Heading}, ${Description} {
        ${tw`font-medium text-white`};
    }

    ${Heading} {}

    ${Description} {
        ${tw`mt-12`};
        font-size: 1rem;
    }
`

// Colours

const Colours = styled.div`
    margin-top: 9rem;

    > ${Heading} {
        margin-bottom: 4rem;
    }

    ${Item} {
        ${tw`flex flex-col`};
        box-shadow: 0 0 10px rgba(0,0,0,0.2);

        ${LoadedImage} {
            ${tw`w-full`};
            height: auto;
        }

        ${Info} {
            ${tw`flex flex-col items-center py-12`};

            ${Heading} {
                ${tw`font-bold`};
                font-size: 1rem;
            }

            ${Description} {
                ${tw`mt-1`};
                &, * {
                    ${tw`font-light`};
                    font-size: 1rem;
                }
            }
        }
    }
`

// Specifications

const Specifications = styled.div`
    margin-top: 4.6875rem;
    padding-bottom: 10rem;

    ${Item} {
        ${tw`w-full py-4 border-b border-black`};

        &, * {
            ${tw`font-light`};
            font-size: 1rem;
        }

        &:first-child {
            ${tw`border-t`};
        }
    }
`

// Features

const Features = styled.div`
    padding-top: 8rem;
    padding-bottom: 9rem;
    ${tw`bg-green`};

    ${props => {
        if (props.highlightColour === 'gold')
            return css`
                ${tw`bg-gold`};
            `
    }}

    ${Container} {
        * {
            ${tw`text-center text-white`};
        }
    }

    ${Heading} {
        margin-bottom: 5rem;
    }

    ${Items} {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-column-gap: 4rem;
        grid-row-gap: 4rem;
        width: 100%;

        ${media.mob`
            grid-template-columns: repeat(2, 1fr);
        `}
    }

    ${Item} {
        ${tw`flex flex-col justify-between items-center`};

        ${Icon} {
            max-height: 5rem;
            max-width: 8rem;
        }

        ${Description} {
            margin-top: 2rem;
            font-size: 1rem;
        }
    }
`

// Gallery

const Gallery = styled.div`
    ${tw`text-center`};
    margin-top: 9rem;

    ${Heading} {
        margin-bottom: 4rem;
    }
`

// Breakdown

const Breakdown = styled.div`
    margin-top: 9rem;
    ${tw`text-center`};

    ${Heading} {
        margin-bottom: 5rem;
    }

    ${LoadedImage} {
        ${tw`w-full mx-auto`};
        max-width: 1240px;
        height: auto;
    }

    /* accreditations */
    ${Items} {
        margin-top: 6rem;
        display: grid;
        grid-column-gap: 6rem;
        margin-left: auto;
        margin-right: auto;

        ${props => {
            const percentage = (props?.itemCount/4)*100 + '%'
            if (percentage)
                return css`
                    width: ${percentage};
                    max-width: 100%;
                    grid-template-columns: repeat(${props?.itemCount}, 1fr);
                `
        }}

        ${media.mob`
            width: 100%;
            grid-column-gap: 2rem;
            grid-row-gap: 3rem;
            grid-template-columns: repeat(2, 1fr);
        `}

        ${Item} {
            ${Icon} {
                max-height: 8rem;
            }
        }
    }
`

const Downloads = styled.div`
    margin-top: 9rem;
    padding-top: 9rem;
    padding-bottom: 9rem;
    ${padding};
    ${tw`text-center`};
    ${bgImage};
    background-image: url(${greyPattern});

    ${Items} {
        ${tw`flex justify-between mx-auto`};
        max-width: 1280px;

        ${media.mob`
            ${tw`flex-col`};
        `}

        ${Item} {
            ${tw`flex flex-col items-center`};
            width: 38%;

            ${media.mob`
                ${tw`w-full`};

                &:last-child {
                    margin-top: 5rem;
                }
            `}

            ${LoadedImage} {
                margin-top: 2rem;
                width: 100%;
                height: auto;
            }

            ${Button} {
                margin-top: 2.5rem;
                ${tw`bg-green border-none`};
                font-size: 1rem;

                &:hover {
                    ${tw`bg-white text-green`};
                }
            }
        }
    }
`

export default ProductSingle
